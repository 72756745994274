.workPage {
    background: #121212;
}

.sectionFooter {
    width: 100%;
    height: 300px;
    background: #121212;
}

@media(max-width:994px) {
    .sectionFooter {
        width: 100%;
        height: 150px;
        background: #121212;
    }
}