.slideFade{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.swiper-left img,.swiper-right img{
    max-width: 100%;
}

.swiper-left,.swiper-right{
    flex: 1;
}