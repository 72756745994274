.ContentContact {
    padding: 100px 0;
}

.ContentContact h1 {
    width: 85%;
    margin: auto;
    color: #FFF;
    text-align: center;
    font-size: 65px;
    font-weight: 900;
    line-height: 1.3;

    text-transform: uppercase;
    margin-bottom: 20px;
}

.ContentContact p {
    width: 85%;
    margin: auto;
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    line-height: 38px;

    text-transform: uppercase;
}

@media(max-width:994px) {
    .ContentContact {
        padding: 80px 0;
    }

    .ContentContact h1 {
        width: 100%;
        font-size: 48px;
        line-height: 1.2;
    }

    .ContentContact p {
        width: 100%;
        font-size: 18px;
        line-height: 1.6;
    }

    .contactForm form label {
        font-size: 22px !important;
    }

    .error {
        font-size: 22px !important;
    }

    .contactForm form {
        width: 100% !important;
    }

    .locationTitle span {
        font-size: 16px !important;
    }
}

@media(max-width:767px) {
    .ContentContact {
        padding: 60px 0;
    }

    .ContentContact h1 {
        width: 100%;
        font-size: 32px;
        line-height: 1.2;
    }

    .ContentContact p {
        width: 100%;
        font-size: 16px;
        line-height: 1.6;
    }

    .contactForm form label {
        font-size: 18px !important;
    }

    .error {
        font-size: 18px !important;
    }

    .contactForm form {
        width: 100% !important;
    }

    .locationTitle span {
        font-size: 14px !important;
    }

    .contactForm form button {
        font-size: 22px !important;
        padding: 6px 40px !important;
    }

    .ContactLocation {
        padding: 60px 20px 80px 20px !important;
    }
}

@media(max-width:480px) {
    .ContentContact {
        padding: 40px 0;
    }

    .ContentContact h1 {
        width: 100%;
        font-size: 24px;
        line-height: 1.2;
    }

    .ContentContact p {
        width: 100%;
        font-size: 15px;
        line-height: 1.6;
    }

    .contactForm form label {
        font-size: 16px !important;
    }

    .error {
        font-size: 16px !important;
    }

    .contactForm form {
        width: 100% !important;
        gap: 10px !important;
    }

    .locationTitle span {
        font-size: 12px !important;
    }

    .contactForm form button {
        font-size: 18px !important;
        padding: 4px 30px !important;
    }

    .ContactLocation {
        padding: 40px 15px 40px 15px !important;
    }
}