.category-container {
    background: #121212;
    min-height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0 60px 0;
    /* margin-bottom: 100px */
}

.category-container .container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* gap: 40px; */
}

.category-container button {
    border: none;
    outline: none;
    background: transparent;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-weight: 900;
    line-height: 38px;

    text-transform: uppercase;
    transition: all .3s;
}

.category-container ul {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-wrap:wrap; */
    gap: 40px;
    overflow: hidden;
    transition: all 1s ease-in-out;
    transform: translateX(-40%);
    max-width: 0;
    max-height: 0;
    opacity: 0;
}

.category-container-ar ul {
    transform: translateX(0%) !important;
}

.category-container .show {
    transition: all 1s ease-in-out;
    max-width: 1000px;
    transform: translateX(0);
    max-height: 1000px;
    opacity: 1;
}

.category-container ul li {
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 38px;

    text-transform: uppercase;
    text-wrap: nowrap;
    cursor: pointer;
    transition: all .3s;
}

.category-container .show li:first-child {
    padding-left: 40px;
}

.category-container .showar li:first-child {
    padding-right: 40px;
    padding-left: 0;
}

.category-container ul li:hover {
    color: #2db7e4;
}

@media(max-width: 994px) {
    .category-container ul li {
        font-size: 14px !important;
    }

    .category-container button {
        font-size: 16px !important;
    }
}

@media(max-width:767px) {
    .category-container ul {
        /* 
        gap: 10px; */
        align-items: flex-start;
        gap: 10px;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .category-container ul li:first-child {
        padding-left: 0 !important;
    }

    .category-container .container {
        /* justify-content: center;
        align-items: center;
        */
        align-items: flex-start;
        justify-content: start;
        gap: 10px;
        flex-direction: column;
    }

    .category-container .showar li:first-child {
        padding-right: 0 !important;
    }
}