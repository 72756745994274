.Environment {
    background-color: #F8F7F4;
    padding-bottom: 20px;
}

.Environment .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
}

.Environment h2 {
    color: #363434;
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: 68px;

    text-transform: uppercase;
}

.Environment p {
    color: #363434;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;

    width: 82%;
}

@media(max-width:994px) {
    .Environment .container {
        gap: 20px;
    }

    .Environment h2 {
        font-size: 54px;
        font-weight: 900;
    }

    .Environment p {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        width: 100%;
    }

}

@media(max-width:776px) {
    .Environment .container {
        gap: 0px;
    }

    .Environment h2 {
        font-size: 32px;
        font-weight: 900;
        line-height: 1.3;
    }

    .Environment p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.6;
        width: 100%;
    }

}

/* 
@media(max-width:450px) {
    .Environment h2 {
        font-size: 24px;
        font-weight: 900;
        line-height: 1.3;
    }

    .Environment p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.6;
        width: 100%;
    }

} */