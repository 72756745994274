.hero {
    width: 100%;
    min-height: 100vh;
    background-image: url('../../../assets/images/bgHero.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 60px 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width:994px) {
    .hero {
        padding: 20px 0 !important;
        min-height: 40vh;
        /* height: 40vh !important; */
    }
}