.categoryCard {
    height: 600px;
    width: 100%;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: all .8s;
}

.categoryCard::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(114deg, rgba(0, 0, 0, 0.42) 4.33%, rgba(0, 0, 0, 0.65) 100%);
    opacity: 0.73;
    transition: all .8s;
}

.categoryCard:hover::before {
    /* background: transparent !important; */
    opacity: 0 !important;
    transition: all .8s;
}

.categoryCard img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media(max-width:994px){
    .categoryCard{
        height:400px;
    }
}
@media(max-width:767px){
    .categoryCard{
        height:300px;
    }
}
@media(max-width:500px){
    .categoryCard{
        height:200px;
    }
}