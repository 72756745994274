.aboutClients {
    padding: 80px 60px;
    margin: auto;
    background-color: #F8F7F4;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 150px;
    direction: ltr !important;
}

.aboutClients .swiper-wrapper {
    width: 1000px !important;
}


.ClientCard {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    max-width: 100%;
    gap: 20px;
}

.ClientCard img {
    width: 100%;
    height: 260px;
    object-fit: cover;
}

.clientInfo h4 {
    color: #363434;
    /* text-align: center; */
    font-size: 22px;
    font-weight: 900;
    margin: 0;

}

.clientInfo span {
    color: #363434;
    /* text-align: center; */
    font-size: 16px;
    font-weight: 400;

}

.clientInfo-ar {
    width: 100%;
    text-align: right !important;
}
.clientInfo-ar h4 {
text-align: right !important;

}

.clientInfo-ar span {
text-align: right !important;

}

.navigation-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
}

.navigation-button.prev {
    left: 0;
}

.navigation-button.next {
    right: 0;
}


@media(max-width:1200px) {
    .aboutClients .swiper-wrapper {
        width: 950px !important;
    }
}

@media(max-width:1024px) {
    .aboutClients .swiper-wrapper {
        width: 800px !important;
    }
}

@media(max-width:994px) {
    .cl-swiper-button-prev svg {
        width: 100px !important;
        height: 50px;
    }

    .cl-swiper-button-next svg {
        width: 100px !important;
        height: 50px;
    }
}

@media(max-width:767px) {
    .cl-swiper-button-prev svg {
        width: 80px !important;
        height: 40px;
    }

    .cl-swiper-button-next svg {
        width: 80px !important;
        height: 40px;
    }
}

@media(max-width:600px) {
    .clientInfo h4 {
        font-size: 18px;
    }

    .clientInfo span {
        font-size: 14px;
    }
}