.header {
    padding: 1rem 0;
    font-family: Roboto !important;
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 1000;
}

.headerContain {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.navbar .menu_nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 70px;
}

.navbar ul li a {
    text-decoration: none;
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;

    text-transform: uppercase;
    transition: all .3s;

}

.navbar ul li a.active {
    color: #2db7e4;
}

.navbar ul li a:hover {
    color: #2db7e4;
}

.logo {
    width: 145.08px;
    height: 53.08px;
}

.logo img {
    max-width: 100%;
    object-fit: contain;
}

.getstart {
    display: flex;
    align-items: center;
    gap: 13px;
}

.getstart .contactBtn {
    outline: none;
    text-decoration: none;
    border: 1px solid #000;
    background: rgba(217, 217, 217, 0.00);
    padding: 4px 16px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 400;

    line-height: 24px;
    color: #000;
    transition: all .3s;
}

.getstart .contactBtn:hover {
    background: #2db7e4;
    color: #fff;
    border: 1px solid #2db7e4;
}

.menu,
.closeMenu {
    display: none;
}

@media (max-width: 994px) {

    .navigation {
        position: fixed !important;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.53);
        z-index: 100;
        display: none !important;
    }

    .menu {
        display: block;
    }

    .menu_nav {
        position: absolute;
        top: 0;
        left: 0;
        width: 300px;
        height: 100%;
        background-color: #fff;
        z-index: 2000;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px !important;

    }
.menu_nav-ar{
    right:0 !important;
    left: auto;
}
    .closeMenu {
        display: block !important;
        position: absolute;
        top: 50px;
        left: 50px;
        z-index: 10000;
    }
    .closeMenu-ar{
        right: 50px !important;
        left: auto;
    }

    .menu_nav a {
        color: #111 !important;
    }

    .active_menu {
        display: block !important;
    }

    .menu svg {
        width: 20px;
        height: 20px;
    }
}

@media(max-width:767px) {
    .logo {
        width: 80px;
        height: auto;
        /* height: 53.08px; */
    }

    .getstart .contactBtn {
        font-size: 18px;
        padding: 2px 8px;
        text-transform: capitalize;
    }
}

@media(max-width:480px) {
    .getstart {
        gap: 5px
    }

    .getstart .contactBtn {
        font-size: 13px;
        padding: 1px 6px;
    }

    .getstart svg {
        width: 22px;
        height: 20px;
    }
}