/* .contactForm {
    padding: 0 0 150px;
} */

.contactForm form {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    gap: 15px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
}

.contactForm form label {
    color: #AAA;
    font-size: 24px;
    font-weight: 500;
    line-height: 2;

    text-transform: capitalize;
}

.contactForm form input,
.contactForm form textarea {
    border-radius: 10px;
    border: 1px solid #777;
    background: #343333;
    padding: 8px 15px;
    font-size: 24px;
    color: #fff;
    resize: none;
}

.contactForm form button {
    border: 1px solid #2DB7E5;
    outline: none;
    border-radius: 10px;
    background: #2DB7E5;
    color: #FFF;
    font-size: 24px;
    font-weight: 500;

    text-transform: capitalize;
    width: fit-content;
    padding: 8px 60px;
    transition: all .5s;
    margin-top: 10px;
}

.contactForm form button:hover {
    border: 1px solid #2db7e5;
    background: #2db7e580;
}

.error {
    height: 25px;
    color: red;
    font-size: 24px;
    /* margin: 15px 0; */
}