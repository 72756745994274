.Dose {
    background: #f8f7f3;
    padding: 100px 0;
}

.doseContain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-right: 100px;
    padding-left: 100px;
}

.doseContain h2 {
    color: #000;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;

}

.doseContain p {
    color: rgba(0, 0, 0, 0.63);
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 100;
    line-height: 74px;

}

.doseContain p span {
    color: #000;
    font-size: 65px;
    font-style: normal;
    font-weight: 250;
    line-height: 74px;

}

.linkMotion {
    margin-top: 60px;
}

.doseContain a {
    text-decoration: none;
    outline: none;
    border: 2px solid #000;
    background: rgba(217, 217, 217, 0.00);
    color: #000;
    text-align: center;
    font-family: Roboto !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;

    padding: 16px 32px;
    transition: all .3s;
}

.doseContain a:hover {
    background: #2db7e4;
    color: #fff;
    border: 2px solid #2db7e4;
}

@media(max-width:994px) {
    .Dose {
        padding: 40px 0;
    }

    .doseContain p {
        font-size: 32px;
        line-height: 1.3;
    }

    .doseContain {
        padding-right: 0;
        padding-left: 0;
    }

    .doseContain button {
        margin-top: 24px;
    }

    .linkMotion {
        margin-top: 40px;
    }
}

@media(max-width:767px) {
    .Dose {
        padding: 40px 0;
    }

    .doseContain h2 {
        font-size: 24px !important;
    }

    .doseContain p {
        font-size: 24px;
        line-height: 1.3;
    }

    .doseContain a {
        font-size: 22px;
        padding: 8px 16px;
        font-weight: 400;
    }

    .linkMotion {
        margin-top: 30px;
    }
}

@media(max-width:480px) {
    .Dose {
        padding: 40px 0;
    }

    .doseContain h2 {
        font-size: 22px !important;
    }

    .doseContain p {
        font-size: 22px;
        line-height: 1.3;
    }

    .doseContain a {
        font-size: 18px;
        padding: 8px 16px;
        font-weight: 400;
    }

    .linkMotion {
        margin-top: 20px;
    }
}