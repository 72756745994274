.HeroAbout {
    height: 100vh;
    background-color: #F8F7F4;
}

.HeroAbout .container {
    height: 100%;
}

.aboutHeroContent {
    height: 100%;
    width: 100%;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.aboutHeroContent h1 {
    color: #FFF;
    text-align: center;
    font-size: 52px;
    font-style: normal;
    font-weight: 900;
    line-height: 68px;
    width: 50%;
}

.aboutHeroContent p {
    color: #FFF;
    font-size: 28px;
    font-style: normal;
    font-weight: 250;
    line-height: 63px;

    text-align: center;
}

@media(max-width:994px) {
    .HeroAbout {
        height: 60vh;
    }

    .aboutHeroContent h1 {
        font-size: 42px;
        line-height: 58px;
    }

    .aboutHeroContent p {
        font-size: 22px;
        line-height: 63px;
    }
}

@media(max-width:767px) {
      .HeroAbout {
        height: 50vh;
    }
    .aboutHeroContent h1 {
        font-size: 24px;
        line-height: 38px;
    }

    .aboutHeroContent p {
        font-size: 14px;
        line-height: 2;
    }
}

@media(max-width:480px) {
    .aboutHeroContent h1 {
        width: 100%
    }
}