.heroStudio {
    height: 100vh;
    width: 100%;
    background-color: #f8f7f3;
}

.heroStudio .swiper {
    width: 100% !important;
    height: 100% !important;
}

.heroStudio .imageHeroStudio {
    height: 100%;
    width: 100%;
    position: relative;
}

.heroStudio .imageHeroStudio::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(32, 56, 72, 0.5);
    /* opacity: .5; */
}

.heroStudio .imageHeroStudio img,
video {
    max-width: 100%;
    width: 100%;
    height: 100%;
    /* max-height: 100%; */
    object-fit: cover;
}
.imageHero{
    height: 100%;
}
.custom-video {
    width: 100%;
    max-height: 650px;
    object-fit: cover;
}

.heroStudio .swiper-button-prev:after,
.heroStudio .swiper-button-next:after {
    font-size: 24px !important;
    color: #fff;
}
.heroStudio-ar{
    direction: ltr !important;
}

.heroStudio .swiper-button-next,
.heroStudio .swiper-button-prev {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    width: 30px;
    height: 60px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.heroStudio .swiper-button-next:hover,
.heroStudio .swiper-button-prev:hover {
    background-color: rgba(0, 0, 0, 0.7);
}

@media(max-width:994px) {
    .heroStudio {
        height: 60vh;
    }
}

@media (max-width: 767px) {

    .heroStudio .swiper-button-next,
    .heroStudio .swiper-button-prev {
        width: 20px;
        height: 40px;
    }

    .heroStudio .swiper-button-prev:after,
    .heroStudio .swiper-button-next:after {
        font-size: 12px !important;
    }

    .heroStudio {
        height: 60vh !important;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .heroStudio {
        height: 40vh !important;
        width: 100%;
    }
}