.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999999 !important;
}

.AboutModal {
    background: white;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    overflow-y: auto;
    padding: 100px 0;
    position: relative;
    animation: modalFadeIn 0.3s ease forwards;
}


.AboutModal h4 {
    color: #000;
    text-align: center;
    font-size: 95px;
    font-style: normal;
    font-weight: 900;
    line-height: 101px;
    letter-spacing: -3px;
    text-transform: capitalize;
}

.AboutModal>svg {
    position: absolute;
    right: 100px;
    top: 50px;
    cursor: pointer;
}

.AboutModal ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.AboutModal>.container>ul>li {
    padding: 100px 0;
    border-bottom: 1px solid #000;
    width: 100%;
}

.Faqtitle {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.AboutModal ul li span {
    flex: .5;
    color: #000;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 0px;

}

.rotate {
    transition: all .3s;
    transform: rotate(45deg);
}

.AboutModal ul li svg {
    cursor: pointer;
}

.faqActive span {
    color: #708eed !important;
}

.faqActive svg path {
    stroke: #708eed;
}

.Faqdescription {
    /* padding: 40px 0; */
    padding: 0;
    overflow: hidden;
    display: none;
    animation: collapse 0.3s ease-in-out forwards;
}

.Faqdescription {
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 50px 150px 0;
    font-size: 24px;
}

.showDescription {
    display: block;
    animation: expand 0.3s ease-in-out forwards;
}

@keyframes expand {
    from {
        max-height: 0;
        padding: 0;
    }

    to {
        height: 100%;
        padding: 50px 150px 0;
        ;
    }
}

@keyframes collapse {
    from {
        height: 100%;
        padding: 50px 150px 0;
    }

    to {
        height: 0;
        padding: 0;
    }
}



@keyframes modalFadeIn {
    0% {
        opacity: 0;
        /* transform: translateY(20px); */
    }

    100% {
        opacity: 1;
        /* transform: translateY(0); */
    }
}

@keyframes modalFadeOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        transform: translateY(20px);
    }
}


@media(max-width:994px) {
    .AboutModal h4 {
        font-size: 54px;
        letter-spacing: .5px;
    }

    .AboutModal ul li span {
        flex: 1;
    }

    .AboutModal {
        padding: 60px 0;
    }

    .AboutModal>svg {
        top: 10px;
    }
}

@media(max-width:767px) {
    .AboutModal ul li span {
        font-size: 24px;
    }

    .AboutModal>svg {
        right: 10px;
        width: 50px;
        height: 50px;
    }

    .AboutModal h4 {
        font-size: 42px;
    }
}

@media(max-width:400px) {
    .AboutModal ul li span {
        font-size: 16px;
    }

    .AboutModal h4 {
        font-size: 32px;
        line-height: 1.3;
    }

    .AboutModal ul li {
        padding: 40px 0;
    }
}