.WorkProgress {
    padding: 150px 0 350px;
    position: relative;
}

.WorkProgress .container {
    height: 100%;
    /* position: relative; */
}

.WorkProgress h3 {
    padding-bottom: 30px;
    border-bottom: 1px solid #B6B6B6;
    color: #000;
    font-size: 60px;
    font-style: normal;
    font-weight: 800;
    line-height: 54px;

    opacity: .5;
}

.WorkProgress ul li {
    border-bottom: 1px solid #B6B6B6;
    padding: 20px 0;
}

.WorkProgress ul li span {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    color: #000;
    line-height: 54px;

    text-transform: uppercase;
    transition: all .3s;
    cursor: pointer;

}

.WorkProgress ul li span:hover {
    color: #2db7e4;
}

.imageProgress {
    position: absolute;
    top: 5%;
    right: 5%;
    height: 80%;
    width: 626px !important;
    transition: all .3s;
    z-index: 200000 !important;
    overflow: hidden;
    padding: 20px
        /* display: none; */
}

.imageProgressAr {
    left: 5% !important;
    right: auto;
}

.imageProgress img {
    width: 100% !important;
    height: 100%;
    object-fit: contain;
}

@media(max-width:994px) {
    .WorkProgress {
        height: 100%;
        padding: 50px 0;
        /* padding-bottom: 120px; */
    }

    .WorkProgress h3 {
        font-size: 42px;
    }

    .imageProgress {
        top: 80px;
        right: -10%;
    }

    .imageProgressAr {
        left: -5% !important;
        right: auto;
    }

    .imageProgress img {
        object-fit: contain;
    }
}

@media(max-width:767px) {
    .WorkProgress {
        height: 100%;
        padding: 50px 0;
        /* padding-bottom: 120px; */
    }

    .WorkProgress h3 {
        font-size: 32px;
        padding-bottom: 16px;
    }

    .WorkProgress ul li {
        font-size: 24px;
        padding: 16px 0;
        line-height: 2;
    }

    .imageProgress {
        top: 70px;
        right: 2%;
        width: 250px !important;
    }

    .imageProgressAr {
        left: 0 !important;
        right: auto;
    }
}

@media(max-width:480px) {
    .WorkProgress h3 {
        font-size: 24px;
        padding-bottom: 12px;
    }

    .WorkProgress ul li span {
        font-size: 18px;
        padding: 12px 0;
        line-height: 2;
    }

    .imageProgress {
        top: 80px;
        right: 0;
        width: 250px !important;
    }

    .imageProgressAr {
        left: 0 !important;
        right: auto;
    }
}