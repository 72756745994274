.ProductsSwiper {
    width: 100%;
    background: #f8f7f3;
    padding-bottom: 100px;
    direction: ltr !important;
}

.ProductsSwiper .swiper-slide {
    margin-right: 0 !important;
    height: 400px;
}

.ProductsSwiper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media(max-width:767px) {
    .ProductsSwiper .swiper-slide {
        height: 250px;
    }
}