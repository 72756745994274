.SwiperEnv {
    width: 100%;
    height: 100%;
    padding: 0 50px;
    margin: 0 auto;
    background-color: #F8F7F4;
    direction: ltr !important;
}

.SwiperEnv .swiper-slide {
    width: 100% !important;
    height: 600px;
    overflow: hidden;
}

.customSwipe img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media(max-width:994px) {
    .SwiperEnv {
        padding: 0 30px;
    }

    .env-swiper-button-next {
        right: 5% !important;
    }

    .env-swiper-button-prev {
        left: 5% !important;
    }
}

@media(max-width:767px) {
    .SwiperEnv {
        padding: 0 20px;
    }

    .SwiperEnv .swiper-slide {
        height: 400px;
    }

    .env-swiper-button-prev {
        left: 0 !important;
    }

    .env-swiper-button-next {
        right: 0 !important;
    }

    .env-swiper-button-prev svg {
        width: 29px;
        height: 50px;
    }

    .env-swiper-button-next svg {
        width: 29px;
        height: 50px;
    }
}

@media(max-width:500px) {
    .SwiperEnv {
        padding: 0 10px;
    }

    .SwiperEnv .swiper-slide {
        height: 200px;
    }

    .env-swiper-button-prev svg {
        width: 20px;
        height: 30px;
    }

    .env-swiper-button-next svg {
        width: 20px;
        height: 30px;
    }
        .customSwipe img {

            object-fit: cover;
        }
}