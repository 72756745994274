.HeroDetails{
    width: 100%;
    max-height: 120vh;
    /* height: 80vh; */
    overflow: hidden;
}
.HeroDetails img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}