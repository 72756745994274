footer {
    background: #000;
    padding: 80px 0 30px;
    position: relative;
}

footer .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 80px;
    position: relative;
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.InfoFooter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
}

.InfoFooter h4 {
    color: #FFF;
    font-size: 80px;
    font-style: normal;
    font-weight: 600;
    line-height: 54px;
    /* 53.465% */

}

.InfoFooter p {
    color: #FFF;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 35px;
    /* 109.375% */

    margin: 0;
}

.InfoFooter a {
    text-decoration: none;
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 54px;
    /* 270% */

}

.InfoFooter .phone {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    /* 150% */
    letter-spacing: 1.5px;
}

.LogoFooter {
    position: absolute;
    z-index: 100;
    right: 0;
    top: -180px;
}

.logoFooterAr {
    left: 0 !important;
    right: auto;
    transform: rotateY(180deg);
}

.secFooter ul {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

.secFooter ul li a {
    text-decoration: none;
    color: #FFF;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 54px;

    transition: all .3s;
    /* text-transform: uppercase; */
}

.secFooter ul li a:hover {
    color: #2db7e4;
}

@media(max-width:1200px) {
    .LogoFooter svg {
        width: 400px;
        height: 400px;
    }

    .InfoFooter h4 {
        font-size: 60px;
    }
}

@media(max-width:994px) {
    .LogoFooter {
        display: none;
    }

    .footer {
        align-items: center;
        justify-content: center;
    }

    .InfoFooter {
        align-items: center;
    }

    .InfoFooter .phone {
        align-items: center;
    }

    .InfoFooter h4 {
        font-size: 60px;
    }
}

@media(max-width:767px) {
    .InfoFooter h4 {
        font-size: 42px;
    }

    .InfoFooter p {
        font-size: 16px;
        line-height: 1.3;
        text-align: center;
    }

    footer .container {
        gap: 40px;
    }

    .secFooter ul {
        gap: 10px;
    }

    .secFooter ul li a {
        line-height: 16px;
    }
}

@media(max-width:400px) {
    footer {
        padding: 30px 0;
    }

    .InfoFooter h4 {
        font-size: 24px;
        text-align: center;
    }

    .InfoFooter p {
        font-size: 14px;
    }

    .InfoFooter a {
        font-size: 14px;
    }

    .secFooter ul li a {
        font-size: 14px;
    }
}