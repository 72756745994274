.DetailsDescription {
    background: #121212;
    padding: 85px 0;
    min-height: 70vh;
    display: flex;
}

.containerDescription {
    display: flex;
    justify-content: center;
    gap: 200px;
    width: 100%;
    height: 100%;
}

.WorkService {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    padding: 40px 0;
}

.WorkService::before,
.WorkService::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #fff;
    left: 0;
}

.WorkService::before {
    top: 0px;
}

.WorkService::after {
    bottom: 0;
}


.WorkServiceList h4 {
    color: #FFF;
    /* text-align: center; */
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;

    text-transform: uppercase;
}


.WorkServiceList ul li {
    color: #FFF;
    /* text-align: center; */
    font-size: 22px;
    font-weight: 300;
    line-height: 38px;

    text-transform: uppercase;
}


.WorkDescription {
    flex: 1;
    overflow: hidden;
    position: relative;
    padding: 40px 0;
}

.WorkDescription::before,
.WorkDescription::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #fff;
    left: 0;
}

.WorkDescription::before {
    top: 0px;
}

.WorkDescription::after {
    bottom: 0;
}

.WorkDescription p {
    /* width: 95%; */
    color: #FFF;
    font-size: 22px;
    font-weight: 300;
    line-height: 38px;

    text-transform: uppercase;
}

@media(max-width:994px) {
    .containerDescription {
        gap: 100px;
    }

    .WorkDescription {
        flex: auto;
    }

    .WorkDescription p {
        font-size: 16px;
        line-height: 34px;
    }

    .WorkServiceList h4 {
        font-size: 18px;
    }

    .WorkServiceList ul li {
        font-size: 16px;
    }
}

@media(max-width:767px) {
    .containerDescription {
        gap: 50px;
    }
    .containerDescription {
        flex-direction: column-reverse;
    }
}
