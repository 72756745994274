.AboutSection {
    background-color: #F8F7F4;
    padding: 80px 0;
}

.AboutSection .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 25px;
}

.AboutSection h2 {
    color: #363434;
    text-align: center;
    font-size: 64px;
    font-style: normal;
    font-weight: 900;
    line-height: 68px;

}

.AboutSection p {
    color: #363434;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;

    width: 82%;
}

.ToolsSection {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.cardTools {
    overflow: hidden;
    position: relative;
    width: 500px;
    min-height: 500px;
    cursor: pointer;
}

.cardTools img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all .5s;
}

.cardTools:hover img {
    transform: scale(1.3) !important;
}

.cardTools-content {
    position: relative;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    color: #fff;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    gap: 40px;
}

.cardTools-content h3 {
    font-size: 32px;
    width: 70%;
    text-align: center;
    letter-spacing: 8px;
    text-transform: uppercase;
    line-height: 44px;
    /* text-wrap:wrap; */
}

.cardTools-content-ar h3 {
    letter-spacing: 0 !important;
}

.cardTools-content ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.cardTools-content ul li {
    font-size: 24px;
    font-weight: 200;
    text-transform: capitalize;
}

@media(max-width:994px) {
    .AboutSection {
        padding: 40px 0;
    }

    .AboutSection .container {
        gap: 20px;
    }

    .AboutSection h2 {
        font-size: 54px;
        font-weight: 900;
    }

    .AboutSection p {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        width: 100%;
    }
/* 
    .cardTools {
        width: 380px;
        min-height: 500px;
    } */

    .cardTools-content h3 {
        font-size: 28px;
    }
}

@media(max-width:776px) {
    .AboutSection {
        padding: 30px 0;
    }

    .AboutSection .container {
        gap: 0px;
    }

    .AboutSection h2 {
        font-size: 32px;
        font-weight: 900;
    }

    .AboutSection p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.6;
        width: 100%;
    }

    .ToolsSection {
        flex-wrap: wrap;
        gap: 30px;
    }

    .cardTools {
        width: 100%;
    }

    .cardTools-content h3 {
        font-size: 32px;
    }

    .cardTools-content {
        gap: 20px;
    }
}

@media(max-width:450px) {
    .cardTools-content h3 {
        font-size: 24px;
        line-height: 32px;
    }

    .cardTools-content ul li {
        font-size: 18px;
    }

}