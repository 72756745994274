.HeroWorks {
    background: #121212;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.HeroWorks .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.HeroWorks h1 {
    color: #FFF;
    text-align: center;
    font-size: 70px;
    font-style: normal;
    font-weight: 900;
    line-height: 1.3;

    text-transform: uppercase;
    width: 90%;
}

.HeroWorks p {
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
    line-height: 38px;

    text-transform: uppercase;
    width: 70%;
}

@media(max-width:994px) {
    .HeroWorks {
        min-height: 50vh;
        padding: 60px 0;
    }


    .HeroWorks .container {
        gap: 30px;
    }

    .HeroWorks h1 {
        font-size: 54px;
        line-height: 1.3;
    }

    .HeroWorks p {
        font-size: 18px;
        width: 100%;
    }

}

@media(max-width:767px) {
    .HeroWorks h1 {
        font-size: 24px;
        line-height: 1.3;
    }

    .HeroWorks p {
        font-size: 14px;
        width: 100%;
    }
}