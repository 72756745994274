.clients {
    padding: 80px 0;
}

.clientsImg {
    position: relative;
    width: 99px;
    height: 102px;
    border: 4px solid #000;
    border-radius: 50%;
    margin-bottom: 50px;
}

.woven {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.clients .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 100px; */
}

.clients p {
    color: #000;
    text-align: center;
    font-size: 41px;
    font-style: normal;
    font-weight: 400;
    line-height: 54px;

    margin-bottom: 100px;
}

.clientHead {
    color: #000;
    text-align: center;
    font-size: 54px;
    font-style: normal;
    font-weight: 800;
    line-height: 54px;

    margin-bottom: 100px;
}

@media(max-width:767px) {
    .clients {
        padding: 40px 0 !important;
    }

    .clients p {
        font-size: 24px;
        line-height: 1.3;
        margin-bottom: 40px;
    }

    .clientsImg {
        width: 60px;
        height: 60px;
        margin-bottom: 25px;
    }

    .clientsImg svg {
        width: 40px;
        height: 40px;
    }

    .clientHead {
        font-size: 32px;
        line-height: 1.3;
        margin-bottom: 25px;
    }
}
@media(max-width:480px){
    .clientHead {
        font-size: 24px;
        line-height: 1.3;
        margin-bottom: 25px;
    }  
}