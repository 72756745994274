.WorkList {
    padding: 60px 0 200px;
}

.WorkList img {
    width: 100%;
}

@media(max-width:994px) {
    .WorkList {
        padding: 60px 0;
    }
}