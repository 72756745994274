/* NotFound.css */
.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: #f0f0f0;
    color: #333;
  }
  
  .not-found h1 {
    font-size: 10rem;
    margin: 0;
  }
  
  .not-found p {
    font-size: 1.5rem;
    margin: 1rem 0;
  }
  
  .not-found a {
    color: #007bff;
    text-decoration: none;
    font-size: 1.2rem;
    border: 1px solid #007bff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .not-found a:hover {
    background-color: #007bff;
    color: white;
  }
  